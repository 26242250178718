import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import Typography from '@mui/material/Typography';

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const SecondarySchool= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container  maxWidth={0.78}  paddingTop={'0 !important'}>
          <Grid>
          <Typography variant={'h4'} sx={{ fontWeight: 700 }}
              align={'center'}>
                Senior School <br/>
              </Typography>
              <Typography component='p' align={'justify'} color="text.secondary">
             <br/>Within a supportive, structured environment we aim to develop independent learners who are well prepared to cope up with future challenges and the world beyond school. Equal importance is laid on students for preparing them for the prescribed CBSE syllabus and for the various competitive entrance examinations.
<br/><br/>Mastery and excellence is the focus of our Senior School programme for Classes 9 to 12. The School offers electives in Science and Commerce subjects at the Senior Secondary level. This programme attaches great importance to academic quality and intellectual expertise and empowers students to excel in various learning subjects. This wing grooms the students to excel academically and to perform well in Board Exams. By the time a child completes the two years of Senior School, he will be prepared to face academic challenges along with emotional balance and acumen to step into the competitive world outside. 
<br/>
              </Typography>
          </Grid>
        </Container>
        <Container maxWidth={0.78}  paddingTop={'0 !important'}>
      <Form />
      </Container>
    </Main>
  );
};


export default SecondarySchool;
